
















import { Component, Vue } from 'vue-property-decorator';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';
import { generalDataService } from '@/services/GeneralDataService';
import ErrorBar from '@/components/common/ErrorBar.vue';
import Header from '@/components/common/Header.vue';

@Component({
  components: { Header, ErrorBar, GeneralCrudList },
})
export default class StructureConfigurationParams extends Vue {
  model: any = [
    { name: 'label', label: 'Label', type: 'text' },
    { name: 'name', label: 'Nazwa', type: 'text' },
    {
      name: 'type',
      label: 'Typ wartości',
      type: 'select',
      options: [
        { id: 1, name: 'Tekst' },
        { id: 2, name: 'Wartości po przecinku' },
        { id: 3, name: 'Liczba' },
        { id: 4, name: 'Wybór' },
      ],
    },
    {
      name: 'options',
      label: 'Wartości do wyboru dla pola typu "Wybór" - oddzielone przecinkiem (np. 0|Opcja 1,1|Opcja 2)',
      type: 'text',
    },
  ];
  listOptions: any = [
    { field: 'label', label: 'Label' },
    { field: 'name', label: 'Nazwa' },
    {
      field: 'type',
      label: 'Typ',
      options: {
        1: 'Tekst',
        2: 'Wartości po przecinku',
        3: 'Liczba',
        4: 'Wybór',
      },
    },
  ];
  structureName = 'Struktura';
  isError = false;
  mounted() {
    const structureId = this.$route.params.id || '0';
    generalDataService
      .getItem(structureId, 'generalList/structure')
      .then((data: any) => {
        this.structureName = data.name;
      })
      .catch((error: any) => {
        if (error.status === 404) {
          this.isError = true;
        }
      });
  }
}
